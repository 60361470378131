import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { getServiceIdByName, getServiceName } from '../../utils/general';
import { ServiceStringsType } from '../../models/service.model';

export type InstanceList = {
    instances: {
        serviceNow: any;
        salesForce: any;
        office365: any;
    };
    instancesOnlyFullScans: {
        serviceNow: any;
        salesForce: any;
        office365: any;
    };
};

@Injectable({
    providedIn: 'root'
})
export class InstanceService {
    constructor(private http: HttpClient) {}

    getInstance(
        id: string | number,
        service: ServiceStringsType
    ): Observable<any> {
        const instanceUrl = `${environment.apiUrl}/v2/${service}-instance/${id}`;
        const serviceId = getServiceIdByName(service);
        return this.http.get(instanceUrl).pipe(
            map((instance: any) => ({
                id: instance.data.id,
                serviceId,
                ...instance.data.attributes,
                dashboards: []
            }))
        );
    }

    instancesListLoad(): Observable<InstanceList> {
        const url = `${environment.apiUrl}/v2/instance?filter[with_scans]=true`;
        return this.http.get(url).pipe(
            map((data: any) =>
                data.data.reduce(
                    (result: InstanceList, item: any) => {
                        const key = getServiceName(
                            item.attributes['service-id']
                        );
                        if (!result.instances.hasOwnProperty(key))
                            console.error('unknown service');
                        if (result.instances.hasOwnProperty(key))
                            result.instances[key].push(item);
                        if (item.attributes['only-full-scans'])
                            result.instancesOnlyFullScans[key].push(item);
                        return result;
                    },
                    {
                        instances: {
                            serviceNow: [],
                            salesForce: [],
                            office365: []
                        },
                        instancesOnlyFullScans: {
                            serviceNow: [],
                            salesForce: [],
                            office365: []
                        }
                    }
                )
            ),
            catchError(() => [])
        );
    }
}
