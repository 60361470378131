import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NothingFoundComponent } from './components/nothing-found/nothing-found.component';
import { CustomPreloadStrategyService } from './custom-preload-strategy.service';
import { ParentComponent } from './pages/parent/parent.component';
import { QCAuthGuard } from './core/auth-lib';
import { DownloadComponent } from './shared/download/download.component';
import { DownloadWrapperComponent } from './shared/download/download-wrapper/download-wrapper.component';
import { NotificationsPageComponent } from './pages/notifications/notifications-page.component';

const routes: Routes = [
    {
        path: 'download',
        component: DownloadWrapperComponent,
        children: [
            {
                path: ':type',
                component: DownloadWrapperComponent,
                children: [
                    {
                        path: ':scanId',
                        component: DownloadComponent
                    }
                ]
            }
        ]
    },
    {
        path: 'notifications',
        component: NotificationsPageComponent
    },
    {
        path: 'all-clouds/:dashboardId',
        component: ParentComponent,
        canActivate: [QCAuthGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./components/all-clouds/all-clouds.module').then(
                        m => m.AllCloudsPageModule
                    )
            },
            {
                path: 'governance',
                loadChildren: () =>
                    import(
                        './components/views/governance/governance-view.module'
                    ).then(m => m.GovernanceViewModule)
            },
            {
                path: 'cross-org-licensing',
                loadChildren: () =>
                    import(
                        './components/views/cross-org-licensing/cross-org-licensing.module'
                    ).then(m => m.CrossOrgLicensingViewModule)
            }
        ]
    },
    {
        path: 'salesforce/:instanceId',
        component: ParentComponent,
        canActivate: [QCAuthGuard],
        children: [
            {
                path: 'executive',
                loadChildren: () =>
                    import(
                        './components/views/executive/executive-view.module'
                    ).then(m => m.ExecutiveViewModule)
            },
            {
                path: 'alerts',
                loadChildren: () =>
                    import('./components/views/alerts/alerts-view.module').then(
                        m => m.AlertsViewModule
                    )
            },
            {
                path: 'quality-in-use',
                loadChildren: () =>
                    import(
                        './components/views/quality-in-use/quality-in-use.module'
                    ).then(m => m.QualityInUseModule)
            },
            {
                path: 'code-monitor-sf',
                loadChildren: () =>
                    import(
                        './components/views/code-monitor/code-monitor-view.module'
                    ).then(m => m.CodeMonitorViewModule)
            },
            {
                path: 'compare-issues',
                loadChildren: () =>
                    import(
                        './components/views/compare-issues/compare-issues-sf/compare-issues-sf.module'
                    ).then(m => m.CompareIssuesSFModule)
            },
            {
                path: 'duplicated-code',
                loadChildren: () =>
                    import(
                        './components/views/duplicated-code/duplicated-code-view.module'
                    ).then(m => m.DuplicatedCodeSFModule)
            },
            {
                path: 'user-adoption',
                loadChildren: () =>
                    import(
                        './components/views/user-adoption-sf/user-adoption-sf-view.module'
                    ).then(m => m.UserAdoptionSFViewModule)
            },
            {
                path: 'profiling',
                loadChildren: () =>
                    import(
                        './components/views/sf-profling/profiling-view.module'
                    ).then(m => m.ProfilingSFViewModule)
            },
            {
                path: 'licensing',
                loadChildren: () =>
                    import(
                        './components/views/sf-licensing/licensing-view.module'
                    ).then(m => m.LicensingViewModule)
            },
            {
                path: 'livecheck',
                loadChildren: () =>
                    import(
                        './components/views/livecheck-sf/live-check-view.module'
                    ).then(m => m.LiveCheckViewSFModule)
            },
            {
                path: 'release-management',
                loadChildren: () =>
                    import(
                        './components/views/feature-branch/feature-branch-view.module'
                    ).then(m => m.FeatureBranchViewModule)
            },
            {
                path: 'peer-review',
                loadChildren: () =>
                    import(
                        './components/views/peer-review/peer-review-view.module'
                    ).then(m => m.PeerReviewViewModule)
            },
            {
                path: 'summary-view',
                loadChildren: () =>
                    import(
                        './components/views/sf-profiling-lite/profiling-lite-view.module'
                    ).then(m => m.ProfilingLiteSFViewModule)
            },
            {
                path: 'impact-analysis',
                loadChildren: () =>
                    import(
                        './components/views/impact-analysis/impact-analysis-view.module'
                    ).then(m => m.ImpactAnalysisViewModule)
            },
            {
                path: 'write-offs',
                loadChildren: () =>
                    import(
                        './components/views/write-offs/write-offs-view.module'
                    ).then(m => m.WriteOffsViewModule)
            },
            {
                path: 'compare-multiple-instances',
                loadChildren: () =>
                    import(
                        './components/views/compare-multiple-instances/compare-multiple-instances-view.module'
                    ).then(m => m.CompareMultipleInstancesViewModule)
            },
            {
                path: 'debt-manager',
                loadChildren: () =>
                    import(
                        './components/views/debt-manager/debt-manager-view.module'
                    ).then(m => m.DebtManagerViewModule)
            },
            {
                path: 'debt-manager-new',
                loadChildren: () =>
                    import(
                        './components/views/debt-manager-new/debt-manager-new-view.module'
                    ).then(m => m.DebtManagerNewViewModule)
            },
            {
                path: 'sf-customization-vs-configuration',
                loadChildren: () =>
                    import(
                        './components/views/sf-customization-vs-configuration/sf-customization-vs-configuration-view.module'
                    ).then(m => m.SFCustomizationVsConfigurationViewModule)
            },
            {
                path: 'devops',
                loadChildren: () =>
                    import(
                        './components/views/asset-manager/asset-manager-view.module'
                    ).then(m => m.AssetManagerViewModule)
            },
            {
                path: 'code-duplication',
                loadChildren: () =>
                    import(
                        './components/views/code-duplication/code-duplication-view.module'
                    ).then(m => m.CodeDuplicationModule)
            }
        ]
    },
    {
        path: 'servicenow/:instanceId',
        component: ParentComponent,
        canActivate: [QCAuthGuard],
        children: [
            {
                path: 'alerts',
                loadChildren: () =>
                    import('./components/views/alerts/alerts-view.module').then(
                        m => m.AlertsViewModule
                    )
            },
            {
                path: 'executive',
                loadChildren: () =>
                    import(
                        './components/views/sn-executive/executive-sn-view.module'
                    ).then(m => m.ExecutiveSNViewModule)
            },
            {
                path: 'livecheck',
                loadChildren: () =>
                    import(
                        './components/views/livecheck/live-check-view.module'
                    ).then(m => m.LiveCheckViewModule)
            },
            {
                path: 'upgradeability',
                loadChildren: () =>
                    import(
                        './components/views/upgradeability/upgradeability-view.module'
                    ).then(m => m.UpgradeabilityViewModule)
            },
            {
                path: 'profiling',
                loadChildren: () =>
                    import(
                        './components/views/profling/profiling-view.module'
                    ).then(m => m.ProfilingViewModule)
            },
            {
                path: 'most-configured-areas',
                loadChildren: () =>
                    import(
                        './components/views/most-configurable-areas/most-configurable-areas-view.module'
                    ).then(m => m.MostConfigurableAreasViewModule)
            },
            {
                path: 'user-adoption',
                loadChildren: () =>
                    import(
                        './components/views/user-adoption/user-adoption-view.module'
                    ).then(m => m.UserAdoptionViewModule)
            },
            {
                path: 'integrations',
                loadChildren: () =>
                    import(
                        './components/views/integrations/integrations-view.module'
                    ).then(m => m.IntegrationsViewModule)
            },
            {
                path: 'code-monitor-sn',
                loadChildren: () =>
                    import(
                        './components/views/code-monitor/code-monitor-view.module'
                    ).then(m => m.CodeMonitorViewModule)
            },
            {
                path: 'compare-issues',
                loadChildren: () =>
                    import(
                        './components/views/compare-issues/compare-issues-sn/compare-issues-sn.module'
                    ).then(m => m.CompareIssuesSNModule)
            },
            {
                path: 'catalog',
                loadChildren: () =>
                    import(
                        './components/views/catalog/catalog-view.module'
                    ).then(m => m.CatalogViewModule)
            },
            {
                path: 'performance',
                loadChildren: () =>
                    import(
                        './components/views/performance/performance-view.module'
                    ).then(m => m.PerformanceViewModule)
            },
            {
                path: 'compare-multiple-instances',
                loadChildren: () =>
                    import(
                        './components/views/compare-multiple-instances/compare-multiple-instances-view.module'
                    ).then(m => m.CompareMultipleInstancesViewModule)
            },
            {
                path: 'customization-vs-configuration',
                loadChildren: () =>
                    import(
                        './components/views/customization-vs-configuration/customization-vs-configuration-view.module'
                    ).then(m => m.CustomizationVsConfigurationViewModule)
            },
            {
                path: 'release-management',
                loadChildren: () =>
                    import(
                        './components/views/release-management/release-management-view.module'
                    ).then(m => m.ReleaseManagementViewModule)
            },
            {
                path: 'peer-review',
                loadChildren: () =>
                    import(
                        './components/views/peer-review/peer-review-view.module'
                    ).then(m => m.PeerReviewViewModule)
            },
            {
                path: 'summary-view',
                loadChildren: () =>
                    import(
                        './components/views/profiling-lite/profiling-lite-view.module'
                    ).then(m => m.ProfilingLiteViewModule)
            },
            {
                path: 'write-offs',
                loadChildren: () =>
                    import(
                        './components/views/write-offs/write-offs-view.module'
                    ).then(m => m.WriteOffsViewModule)
            },
            {
                path: 'debt-manager',
                loadChildren: () =>
                    import(
                        './components/views/debt-manager/debt-manager-view.module'
                    ).then(m => m.DebtManagerViewModule)
            },
            {
                path: 'debt-manager-new',
                loadChildren: () =>
                    import(
                        './components/views/debt-manager-new/debt-manager-new-view.module'
                    ).then(m => m.DebtManagerNewViewModule)
            },
            {
                path: 'devops',
                loadChildren: () =>
                    import(
                        './components/views/asset-manager/asset-manager-view.module'
                    ).then(m => m.AssetManagerViewModule)
            },
            {
                path: 'code-duplication',
                loadChildren: () =>
                    import(
                        './components/views/code-duplication/code-duplication-view.module'
                    ).then(m => m.CodeDuplicationModule)
            }
        ]
    },
    {
        path: 'office365/:instanceId',
        component: ParentComponent,
        canActivate: [QCAuthGuard],
        children: [
            {
                path: 'compare-issues',
                loadChildren: () =>
                    import(
                        './components/views/compare-issues/compare-issues-office/compare-issues-office.module'
                    ).then(m => m.CompareIssuesOFFICEModule)
            },
            {
                path: 'executive',
                loadChildren: () =>
                    import(
                        './components/views/office-executive/executive-office-view.module'
                    ).then(m => m.ExecutiveOfficeViewModule)
            },
            {
                path: 'profiling',
                loadChildren: () =>
                    import(
                        './components/views/office-profiling/profiling-view.module'
                    ).then(m => m.ProfilingOfficeViewModule)
            },
            {
                path: 'compare-multiple-instances',
                loadChildren: () =>
                    import(
                        './components/views/compare-multiple-instances/compare-multiple-instances-view.module'
                    ).then(m => m.CompareMultipleInstancesViewModule)
            },
            {
                path: 'alerts',
                loadChildren: () =>
                    import('./components/views/alerts/alerts-view.module').then(
                        m => m.AlertsViewModule
                    )
            },
            {
                path: 'code-monitor-office',
                loadChildren: () =>
                    import(
                        './components/views/code-monitor/code-monitor-view.module'
                    ).then(m => m.CodeMonitorViewModule)
            },
            {
                path: 'debt-manager',
                loadChildren: () =>
                    import(
                        './components/views/debt-manager/debt-manager-view.module'
                    ).then(m => m.DebtManagerViewModule)
            },
            {
                path: 'debt-manager-new',
                loadChildren: () =>
                    import(
                        './components/views/debt-manager-new/debt-manager-new-view.module'
                    ).then(m => m.DebtManagerNewViewModule)
            },
            {
                path: 'code-duplication',
                loadChildren: () =>
                    import(
                        './components/views/code-duplication/code-duplication-view.module'
                    ).then(m => m.CodeDuplicationModule)
            }
        ]
    },
    {
        path: '**',
        component: NothingFoundComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            scrollPositionRestoration: 'disabled',
            preloadingStrategy: CustomPreloadStrategyService,
            onSameUrlNavigation: 'ignore'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
