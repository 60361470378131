import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import {
    customerAddons,
    instanceSelector,
    scanQualityGateMessageSelector,
    scanQualityGateStatusSelector,
    scanSelector
} from '../../core/state/general/general.selectors';
import { first, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/core.state';
import { BannerType, LinkElement } from '../banner/banner.component';
import { Addon } from '../../core/state/general/general.models';

@Component({
    selector: 'qcbi-qg-banner',
    templateUrl: './qg-banner.component.html'
})
export class QgBannerComponent implements OnInit, OnDestroy {
    scanQualityGateStatus: string;
    scanQualityGateMessage: string;
    hasSalesForceQualityGatesAddon = false;
    hasServiceNowQualityGatesAddon = false;
    hasQualityGatesWithScans = false;
    hasQualityGates = false;
    instance: any;
    scan: any;
    qualityGatesWithScanType = BannerType.WARN;

    private unsubscribe = new Subject<void>();

    constructor(private store: Store<AppState>) {}

    ngOnInit() {
        this.store
            .select(scanQualityGateStatusSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(res => {
                this.scanQualityGateStatus = res;
            });

        this.store
            .select(scanQualityGateMessageSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(res => {
                this.scanQualityGateMessage = res;
            });

        this.store
            .select(customerAddons)
            .pipe(first())
            .subscribe((addons: Addon[]) => {
                this.hasSalesForceQualityGatesAddon =
                    addons &&
                    addons.some(addon => addon.nameId === 'qualitygates_sf');
                this.hasServiceNowQualityGatesAddon =
                    addons &&
                    addons.some(addon => addon.nameId === 'qualitygates_sn');
            });

        this.store
            .select(instanceSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(inst => {
                this.instance = inst;
                if (this.instance) {
                    this.hasQualityGatesWithScans = this.instance[
                        'has-quality-gates-with-scan'
                    ];
                    this.hasQualityGates = this.instance['has-quality-gates'];
                }
            });

        this.store
            .select(scanSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(scan => {
                this.scan = scan;
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getQualityGatesMessage(): string {
        return (!this.hasSalesForceQualityGatesAddon &&
            this.instance.serviceId === 2) ||
            (!this.hasServiceNowQualityGatesAddon &&
                this.instance.serviceId === 1)
            ? 'You have not installed the Quality Gate add-on yet. Find out ${0}, and ${1}.'
            : !this.hasQualityGates
            ? 'You have not defined any quality gate for this instance at this scan date. Find out more on ${0}'
            : !this.hasQualityGatesWithScans
            ? 'You have not launched any full scan after activating the Quality Gate yet.'
            : this.scanQualityGateStatus === 'PASSED'
            ? 'Quality Gate passed'
            : this.scanQualityGateMessage || this.scanQualityGateStatus;
    }

    getQualityGatesLink(): LinkElement[] {
        if (
            (!this.hasSalesForceQualityGatesAddon &&
                this.instance.serviceId === 2) ||
            (!this.hasServiceNowQualityGatesAddon &&
                this.instance.serviceId === 1)
        ) {
            return [
                {
                    text: 'more',
                    href: 'https://marketing.qualityclouds.com/quality-gates'
                },
                {
                    text: 'contact your sales representative.',
                    href: 'mailto:sales@qualityclouds.com'
                }
            ];
        }
        if (!this.hasQualityGates)
            return [
                {
                    text: 'our doc site',
                    href:
                        'https://docs.qualityclouds.com/qcd/quality-gates-94110036.html'
                }
            ];
        return undefined;
    }

    getBannerTypeFromQualityGate(): BannerType {
        if (
            (!this.hasSalesForceQualityGatesAddon &&
                !this.hasServiceNowQualityGatesAddon) ||
            !this.hasQualityGates
        )
            return BannerType.INFO;
        if (this.hasQualityGates && !this.hasQualityGatesWithScans)
            return BannerType.WARN;
        switch (this.scanQualityGateStatus) {
            case 'FAIL':
                return BannerType.ERROR;
            case 'PASSED':
                return BannerType.SUCCESS;
            default:
                return BannerType.INFO;
        }
    }
}
