import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';
import { SharedModule } from './shared/shared.module';
import { NothingFoundComponent } from './components/nothing-found/nothing-found.component';
import { ParentComponent } from './pages/parent/parent.component';
import { NotificationsPageModule } from './pages/notifications/notifications-page.module';
import { DrawerModule } from './shared/drawer/drawer.module';
import { TwoFactorAuthenticationModule } from './shared/two-factor-authentication/two-factor-authentication.module';
import { NewFeaturesModule } from './core/new-features/new-features.module';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/autocomplete';

@NgModule({
    imports: [
        // angular
        BrowserAnimationsModule,
        BrowserModule,
        // core & shared
        SharedModule,
        CoreModule,
        // app
        AppRoutingModule,
        NotificationsPageModule,
        DrawerModule,
        TwoFactorAuthenticationModule,
        NewFeaturesModule
    ],
    declarations: [AppComponent, NothingFoundComponent, ParentComponent],
    bootstrap: [AppComponent],
    providers: [MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER]
})
export class AppModule {}
