export const SERVICENOW_ID = 1;
export const SALESFORCE_ID = 2;
export const OFFICE_ID = 3;
export const ALL_CLOUDS = 99;

export const NOTIFICATIONS_FETCH_TIME = 5 * 60 * 1000; // 5 minutes

export const SERVICES = ['serviceNow', 'salesForce', 'office365'];

export const SERVICES_IDS = [SERVICENOW_ID, SALESFORCE_ID, OFFICE_ID];

export const ROLES = {
    QC_ADMIN: 1,
    CUSTOMER_ADMIN: 2
};

export const SEVERITIES = {
    WARNING: '4',
    LOW: '3',
    MEDIUM: '2',
    HIGH: '1'
};
