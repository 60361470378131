import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { SERVICES_IDS } from '../../../utils/constants';

@Injectable({
    providedIn: 'root'
})
export class ScanService {
    constructor(private http: HttpClient) {}

    checkLastScanStatus(instanceId: number) {
        const filters = [
            `filter[url]=${instanceId}`,
            'filter[scan_type]=0',
            'page[size]=1',
            'page[number]=1',
            'sort=-scan_date'
        ];
        return this.http
            .get(`${environment.apiUrl}/v2/scan?` + filters.join('&'))
            .pipe(
                map((res: any) => {
                    if (res.data.length === 0) return false;
                    return res.data[0].attributes?.status === 'RUNNING';
                })
            );
    }

    getScansByInstance(instanceId: string | number): Observable<any[]> {
        const url = `${environment.apiEndPoint}widgets/v1/scans/${instanceId}?isProfiling=false&without_warnings=true`;
        return this.http.get(url).pipe(
            map((response: any) => {
                return response.data.reverse().map((scan: any) => ({
                    value: parseInt(scan.value, 10),
                    label: scan.label
                }));
            }),
            catchError(err => {
                console.error(err);
                return of([]);
            })
        );
    }

    getProfilingScansByInstance(
        instanceId: number | string
    ): Observable<any[]> {
        const url = `${environment.apiEndPoint}widgets/v1/scans/${instanceId}?without_warnings=true`;
        return this.http.get(url).pipe(
            map((response: any) => {
                return response.data.reverse().map((scan: any) => ({
                    value: parseInt(scan.value, 10),
                    label: scan.label
                }));
            }),
            catchError(err => {
                console.error(err);
                return of([]);
            })
        );
    }

    getScan(
        scanId: number
    ): Observable<{ value: number; label: string; longId: string }> {
        if (scanId === null) return throwError(() => new Error('No scan'));
        const url = `${environment.apiDevEndPoint}api/v2/scan/1?shortId=${scanId}&without_warnings=true`;
        return this.http.get(url).pipe(
            map((response: any) => {
                return {
                    id: response.data.id,
                    ...response.data.attributes
                };
            }),
            catchError(error => {
                console.error(error);
                return [new Error(error)];
            })
        );
    }

    getLastScansAllInstances(customerId: number) {
        const filters = [
            'filter[scan_type]=0',
            'page[number]=1',
            'page[size]=1',
            'filter[status_detail]=LOADED',
            `filter[customer_id]=${customerId}`
        ];
        const url = `${environment.apiUrl}/v2/scan?`;
        return forkJoin(
            SERVICES_IDS.map(serviceId => {
                const completeUrl =
                    url +
                    filters.concat([`filter[provider]=${serviceId}`]).join('&');
                return this.http.get(completeUrl);
            })
        );
    }
}
