import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NewFeaturesActions } from './new-features.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { NewFeaturesService } from './new-features.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GeneralActions } from '../state/general/general.actions';

@Injectable()
export class NewFeaturesEffects {
    getNewFeatures$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeneralActions.initialLoad),
            switchMap(() =>
                this.newFeaturesService.getNewFeatures().pipe(
                    map(features => {
                        return NewFeaturesActions.newFeaturesFetched({
                            features
                        });
                    }),
                    catchError(err => {
                        console.error(err);
                        this.snackBar.open('something went wrong', null, {
                            duration: 3000,
                            panelClass: 'error-notification-overlay'
                        });
                        return [NewFeaturesActions.newFeatureError()];
                    })
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private newFeaturesService: NewFeaturesService,
        private snackBar: MatSnackBar
    ) {}
}
