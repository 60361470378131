import { createSelector, createFeatureSelector } from '@ngrx/store';

import { AuthLibState, AuthLibStatus } from './models';

export const selectAuthState = createFeatureSelector<any, AuthLibState>('auth');

export const selectAuth = createSelector(
    selectAuthState,
    (state: AuthLibState) => state
);

export const selectAuthStatusOn = createSelector(
    selectAuth,
    (state: AuthLibState) => state.status === AuthLibStatus.ON
);

export const selectAuthStatusOff = createSelector(
    selectAuth,
    (state: AuthLibState) => state.status === AuthLibStatus.OFF
);

export const selectIsAuthenticated = createSelector(
    selectAuthState,
    ({ status, isAuthenticated }: AuthLibState) =>
        status === AuthLibStatus.OFF ? true : isAuthenticated
);

export const selectOnEmulation = createSelector(
    selectAuthState,
    ({ onEmulation }: AuthLibState) => onEmulation
);

export const selectCurrentUser = createSelector(
    selectAuthState,
    ({ onEmulation, emulatedUser, user }: AuthLibState) =>
        onEmulation ? emulatedUser : user
);

export const customerIdSelector = createSelector(selectAuthState, auth =>
    auth.onEmulation ? auth.emulatedUser?.customerId : auth.user?.customerId
);

export const selectCurrentUserId = createSelector(
    selectAuthState,
    ({ onEmulation, emulatedUser, user }: AuthLibState) =>
        onEmulation ? emulatedUser.id : user.id
);

export const selectCurrentUserRoleId = createSelector(
    selectAuthState,
    ({ onEmulation, emulatedUser, user }: AuthLibState) =>
        onEmulation ? emulatedUser.role : user.role
);

export const selectEmulatedUser = createSelector(
    selectAuthState,
    ({ emulatedUser }: AuthLibState) => emulatedUser
);

export const selectOnWindow = createSelector(
    selectAuthState,
    ({ onWindow }: AuthLibState) => onWindow
);

export const selectUser = createSelector(
    selectAuthState,
    ({ user }: AuthLibState) => user
);

export const selectWindowId = createSelector(
    selectAuthState,
    ({ windowId }: AuthLibState) => windowId
);

export const logoutOnFocusSelector = createSelector(
    selectAuthState,
    ({ logoutOnFocus }: AuthLibState) => logoutOnFocus
);
