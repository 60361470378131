import { GeneralState, ViewType } from './general.models';
import { GeneralActions as Actions } from './general.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { getDefaultSettings } from '../../../utils/general';

export const initialState: GeneralState = {
    sideMenuExpanded: !(localStorage.getItem('expanded_menu') === 'false'),
    largeWidgetExpanded: false,
    isLoaded: false,
    loading: false,
    error: '',
    view: ViewType.Executive,
    baseFilters: {},
    dashboardFilters: {
        severities: [],
        areas: [],
        tagsByScan: [],
        bestPractices: [],
        createdBy: [],
        updatedBy: [],
        auditUpdatedBy: [],
        cetypes: [],
        cetypesnames: [],
        licensesCustomer: [],
        orgs: [],
        cetypesnamesisootb: [],
        sources: [],
        namespaces: [],
        cmNamespaces: [],
        CInamespaces: [],
        auditElement: [],
        auditApplication: [],
        dateCeTypes: [],
        dateUsers: [],
        dateTeams: [],
        dateCeNames: [],
        dateIssuesCeTypes: [],
        dateIssuesDevelopers: [],
        dateIssuesTeams: [],
        scansFilter: [],
        typeOfChange: [],
        ootbModifications: [],
        updatedByUgr: [],
        cetypesnamesUgr: [],
        dateUpdateSetQgStatus: [],
        dateAppInventoryQgStatus: [],
        dateUpdateSetStatus: [],
        dateUpdateSetName: [],
        dateAppInventoryStatus: [],
        dateUpdateSetReleaseType: [],
        dateFeatureBranchQgStatus: [],
        dateFeatureBranchStatus: [],
        dateFeatureBranchReleaseType: [],
        dateFeatureBranchName: [],
        dateScansCeTypes: [],
        dateScansDevelopers: [],
        dateScansQualityGatesResults: []
    },
    generalFiltersLoaded: false,
    settings: undefined,
    onAllClouds: false,
    initialLoading: true,
    mainFilterSet: false,
    instanceLoading: true,
    scanLoading: false,
    instances: {},
    instancesOnlyFullScans: {},
    instance: null,
    scans: [],
    profilingScans: [],
    scan: null,
    dashboards: [],
    allCloudsDashboards: [],
    dashboardContext: null,
    dashboard: null,
    emptyView: false,
    selectedDateRange: null,
    lastScans: {
        salesforce: undefined,
        servicenow: undefined,
        office365: undefined
    },
    addons: [],
    mainFilter: 'all',
    includeBaseline: true,
    includeTablesAndDictionaries: false,
    userPortalConfiguration: {
        starredInstances: {
            serviceNow: [],
            salesForce: [],
            office365: []
        }
    }
};

const reducer = createReducer(
    initialState,
    on(Actions.initSuccess, (state, { instances }) => ({
        ...state,
        isLoaded: true,
        instances
    })),
    on(Actions.initError, (state, { error }) => ({
        ...state,
        error
    })),
    on(Actions.error, (state, { error }) => ({
        ...state,
        loading: false,
        mainFilterSet: false,
        error,
        isLoaded: true
    })),
    on(Actions.toggleSideMenu, state => ({
        ...state,
        sideMenuExpanded: !state.sideMenuExpanded
    })),
    on(Actions.loadInstance, state => ({
        ...state,
        loading: true,
        emptyView: false,
        error: ''
    })),
    on(Actions.loadInstanceSuccess, (state, { instance }) => ({
        ...state,
        loading: false,
        mainFilterSet: false,
        instance,
        isLoaded: true
    })),
    on(Actions.changeView, (state, { view }) => ({
        ...state,
        view
    })),
    on(Actions.setInstance, state => ({
        ...state,
        loading: true,
        emptyView: false
    })),
    on(Actions.setInstanceSuccess, (state, { instance }) => ({
        ...state,
        instance
    })),
    on(Actions.setScan, state => ({
        ...state,
        loading: true
    })),
    on(Actions.setDashboardDateRange, (state, { range }) => ({
        ...state,
        selectedDateRange: range,
        generalFiltersLoaded: false,
        mainFilterSet: false
    })),
    on(Actions.noScanError, state => ({
        ...state,
        scan: null,
        mainFilterSet: false,
        loading: false
    })),
    on(Actions.setScanSuccess, (state, { scan }) => ({
        ...state,
        loading: false,
        mainFilterSet: false,
        generalFiltersLoaded: true,
        scan
    })),
    on(Actions.saveScans, (state, { scans }) => ({
        ...state,
        scans
    })),
    on(Actions.saveFilters, (state, filters) => ({
        ...state,
        mainFilterSet: false,
        generalFiltersLoaded: true,
        dashboardFilters: {
            ...state.dashboardFilters,
            ...filters
        }
    })),
    on(Actions.saveDashboards, (state, { dashboards }) => ({
        ...state,
        instance: {
            ...state.instance,
            dashboards
        }
    })),
    on(Actions.setDashboard, (state, { dashboard }) => ({
        ...state,
        dashboard
    })),
    on(Actions.setDefaultSettings, state => ({
        ...state,
        settings: getDefaultSettings()
    })),
    on(Actions.updateSettings, (state, { settings }) => ({
        ...state,
        settings
    })),
    // new data flow
    on(Actions.initialLoad, state => ({
        ...state,
        initialLoading: true,
        emptyView: false
    })),
    on(Actions.initialLoadSuccess, (state, { settings, dashboardContext }) => ({
        ...state,
        settings,
        dashboardContext
    })),
    on(
        Actions.instanceListLoadSuccess,
        (state, { instancesOnlyFullScans, instances }) => {
            return {
                ...state,
                instancesOnlyFullScans,
                instances,
                initialLoading: false
            };
        }
    ),
    on(
        Actions.allCloudsDashboardsLoadSuccess,
        (state, { allCloudsDashboards }) => {
            return {
                ...state,
                allCloudsDashboards
            };
        }
    ),
    on(Actions.initialLoadError, (state, { error }) => ({
        ...state,
        initialLoading: false,
        error
    })),

    on(Actions.instanceLoad, state => ({
        ...state,
        onAllClouds: false,
        dashboard: null,
        instanceLoading: true
    })),
    on(Actions.instanceLoadSuccess, (state, { filters, ...data }) => ({
        ...state,
        instanceLoading: false,
        dashboardFilters: {
            ...state.dashboardFilters,
            ...filters
        },
        ...data
    })),
    on(Actions.instanceLoadError, (state, { error }) => ({
        ...state,
        instanceLoading: false,
        error
    })),
    on(Actions.scanLoad, state => ({
        ...state,
        mainFilterSet: false,
        scanLoading: true
    })),
    on(Actions.scanLoadSuccess, (state, { scan, filters }) => ({
        ...state,
        scanLoading: false,
        scan,
        mainFilterSet: false,
        generalFiltersLoaded: true,
        dashboardFilters: {
            ...state.dashboardFilters,
            ...filters
        }
    })),
    on(Actions.scanLoadError, (state, { error }) => ({
        ...state,
        scanLoading: false,
        mainFilterSet: false,
        error
    })),
    on(Actions.allCloudsLoad, (state, { dashboardId }) => ({
        ...state,
        onAllClouds: true,
        mainFilterSet: false,
        instanceLoading: false,
        dashboard: dashboardId
            ? state.allCloudsDashboards.find(
                  dashboard => dashboard.id === dashboardId
              )
            : state.allCloudsDashboards[0],
        instance: null
    })),
    on(Actions.allCloudsInit, (state, { dashboardId, filters = {} }) => ({
        ...state,
        dashboardFilters: {
            ...state.dashboardFilters,
            ...filters
        },
        onAllClouds: true,
        instanceLoading: false,
        mainFilterSet: false,
        dashboard: state.allCloudsDashboards.find(
            dashboard => dashboard.id === dashboardId
        ),
        instance: null
    })),

    on(Actions.emptyView, state => ({
        ...state,
        instanceLoading: false,
        emptyView: true
    })),
    on(Actions.getLastScansSuccess, (state, { lastScans }) => {
        return {
            ...state,
            lastScans
        };
    }),
    on(Actions.invalidateGlobalFilters, state => ({
        ...state,
        generalFiltersLoaded: false
    })),
    on(Actions.setAddons, (state, { addons }) => ({
        ...state,
        addons
    })),
    on(
        Actions.setMainFilter,
        (state, { mainFilter, alreadyLoaded = false }) => ({
            ...state,
            mainFilterSet: !alreadyLoaded,
            mainFilter
        })
    ),
    on(Actions.setUserConfigurationItem, (state, { key, value }) => ({
        ...state,
        userPortalConfiguration: {
            ...state.userPortalConfiguration,
            [key]: value
        }
    })),
    on(Actions.setInitialUserConfiguration, (state, { initialConfig }) => {
        const newUserConfig = {
            ...state.userPortalConfiguration,
            ...initialConfig
        };
        return {
            ...state,
            userPortalConfiguration: newUserConfig
        };
    }),
    on(Actions.toggleLargeWidgetExpansion, state => ({
        ...state,
        largeWidgetExpanded: !state.largeWidgetExpanded
    })),
    on(Actions.toggleIncludeBaseline, state => ({
        ...state,
        includeBaseline: !state.includeBaseline
    })),
    on(Actions.setIncludeBaseline, state => ({
        ...state,
        includeBaseline: true
    })),
    on(Actions.toggleIncludeTablesAndDict, state => ({
        ...state,
        includeTablesAndDictionaries: !state.includeTablesAndDictionaries
    })),
    on(Actions.setIncludeTablesAndDict, state => ({
        ...state,
        includeTablesAndDictionaries: false
    })),
    on(Actions.saveBaseFilters, (state, { baseFilters }) => {
        return {
            ...state,
            baseFilters: {
                ...state.baseFilters,
                ...baseFilters
            }
        };
    }),
    on(Actions.removeBaseFilters, state => {
        return {
            ...state,
            baseFilters: {}
        };
    })
);

export function generalReducer(
    state: GeneralState | undefined,
    action: Action
): GeneralState {
    return reducer(state, action);
}
