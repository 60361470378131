import { DefaultSettings } from '../core/state/general/general.models';
import { ServiceStringsType } from '../models/service.model';
import { OFFICE_ID, SALESFORCE_ID, SERVICENOW_ID } from '../../utils/constants';

export function getDefaultSettings(): DefaultSettings {
    return {
        instanceId: null,
        serviceId: null,
        dashboardId: null
    };
}

export function getFirstDefaultSettings(
    dashboards: any,
    instances: any,
    context: any
): DefaultSettings {
    const _context = context.find((x: any) => x.available === true);
    if (!_context) return null;
    if (
        _context.serviceId === 99 &&
        isAvailableService(context, _context.serviceId)
    ) {
        return {
            serviceId: _context.serviceId,
            dashboardId: dashboards[0].id
        };
    } else if (isAvailableService(context, _context.serviceId)) {
        const serviceKey = getServiceName(_context.serviceId);
        return {
            serviceId: _context.serviceId,
            instanceId: instances[serviceKey][0].id,
            dashboardId: null
        };
    }
    return null;
}

export function getServiceName(id: number): string {
    switch (id) {
        case SERVICENOW_ID:
            return 'serviceNow';
        case SALESFORCE_ID:
            return 'salesForce';
        case OFFICE_ID:
            return 'office365';
    }
}

export function getServicePath(id: number): ServiceStringsType {
    switch (id) {
        case SERVICENOW_ID:
            return 'servicenow';
        case SALESFORCE_ID:
            return 'salesforce';
        case OFFICE_ID:
            return 'office365';
    }
}

export function getServiceIdByName(name: ServiceStringsType): number {
    switch (name) {
        case 'servicenow':
            return SERVICENOW_ID;
        case 'salesforce':
            return SALESFORCE_ID;
        case 'office365':
            return OFFICE_ID;
    }
}

export function isAvailableService(context: any[], serviceId: number) {
    return context.some(x => x.serviceId === serviceId && x.available === true);
}

export function parseDashboardContext(data: any[]) {
    return data.map(item => ({
        serviceId: item.service_id,
        available: item.available,
        name: item.name
    }));
}

export function getYYYYMMDDfromDate(d: Date): string {
    if (d === null) return null;
    const _d = new Date(d);
    return (
        _d.getFullYear().toString() +
        ('0' + (_d.getMonth() + 1)).slice(-2) +
        ('0' + _d.getDate()).slice(-2)
    );
}

export function sortByDate(a: { label: string }, b: { label: string }) {
    return b.label.localeCompare(a.label);
}

export function sortByLabel(
    a: { disabled: boolean; label: string },
    b: { disabled: boolean; label: string }
) {
    return (
        +b.disabled - +a.disabled ||
        (a.label ? a.label.localeCompare(b.label) : 0)
    );
}
