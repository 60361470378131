import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule
} from '@angular/common/http';
import {
    RouterStateSerializer,
    StoreRouterConnectingModule
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { environment } from '../../environments/environment';

import {
    AppState,
    metaReducers,
    reducers,
    selectRouterState
} from './core.state';
import { TitleService } from './title/title.service';
import {
    ROUTE_ANIMATIONS_ELEMENTS,
    routeAnimations
} from './animations/route.animations';
import { AnimationsService } from './animations/animations.service';
import { CustomSerializer } from './router/custom-serializer';
import { LocalStorageService } from './local-storage/local-storage.service';
import { NotificationService } from './notifications/notification.service';
import { SettingsEffects } from './settings/settings.effects';
import {
    selectEffectiveTheme,
    selectSettingsLanguage,
    selectSettingsStickyHeader
} from './settings/settings.selectors';

// livecheck overview widgets
import { StateEffects as UsageSW_Effects } from './state/widgets/small/livecheck-overview/usage.state';
import { StateEffects as UsageLW_Effects } from './state/widgets/large/livecheck-overview/usage.state';
import { StateEffects as ED_SW_Effects } from './state/widgets/small/livecheck-overview/early-detection.state';
import { StateEffects as ED_LW_Effects } from './state/widgets/large/livecheck-overview/early-detection.state';
import { StateEffects as Rm_App_SW_Effects } from './state/widgets/small/livecheck-overview/rm-app.state';
import { StateEffects as Rm_Hist_SW_Effects } from './state/widgets/small/livecheck-overview/rm-hist.state';
import { StateEffects as Fb_SW_Effects } from './state/widgets/small/livecheck-overview/fb.state';

// governance widgets
import { StateEffects as SFSW_Effects } from './state/widgets/small/governance-overview/salesforce.state';
import { StateEffects as SNSW_Effects } from './state/widgets/small/governance-overview/service-now.state';
import { StateEffects as ALSW_Effects } from './state/widgets/small/governance-overview/alerts.state';
import { StateEffects as USFSW_Effects } from './state/widgets/small/governance-overview/usage-salesforce.state';
import { StateEffects as USNSW_Effects } from './state/widgets/small/governance-overview/usage-service-now.state';

import { StateEffects as SFLW_Effects } from './state/widgets/large/governance-overview/salesforce.state';
import { StateEffects as SNLW_Effects } from './state/widgets/large/governance-overview/service-now.state';

// usage effects
import { StateEffects as U_SW_UA_Effects } from './state/widgets/small/usage/ua.state';
import { StateEffects as U_SW_LI_Effects } from './state/widgets/small/usage/li.state';
import { StateEffects as U_SW_GLS_Effects } from './state/widgets/small/usage/gls.state';
import { StateEffects as U_SW_GLAC_Effects } from './state/widgets/small/usage/glac.state';
import { StateEffects as U_LW_UA_Effects } from './state/widgets/large/usage/ua.state';
import { StateEffects as U_LW_LI_Effects } from './state/widgets/large/usage/li.state';
import { StateEffects as U_LW_GLS_Effects } from './state/widgets/large/usage/gls.state';
import { StateEffects as U_LW_GLAC_Effects } from './state/widgets/large/usage/glac.state';

import { GeneralEffects } from './state/general/general.effect';
import { ScanIssuesEffects } from './state/scan-issues/scan-issues.effect';
import { WidgetService } from './services/widget.service';

import { QCAuthEffects, QCAuthInterceptor, QCModule } from './auth-lib';

// code monitor effects
import { StateEffects as AMLW_Effects } from './state/widgets/large/asset-manager/asset-manager.state';

// profiling

// Compare multiple instances
import { StateEffects as CMILW_Effects } from './state/widgets/large/compare-multiple-instances/cmi-large.state';
import { StateEffects as INTSW_Effects } from './state/widgets/small/compare-multiple-instances/int-small.state';

// Most configurable areas
import { StateEffects as MCALW_Effects } from './state/widgets/large/most-configurable-areas/mca.state';

// Customization vs configuration
import { StateEffects as CVSC_SW_CR_Effects } from './state/widgets/small/customization-vs-configuration/cr.state';
import { StateEffects as CVSC_LW_CR_Effects } from './state/widgets/large/customization-vs-configuration/cr.state';

// User adoption
import { StateEffects as AUSW_Effects } from './state/widgets/small/user-adoption/au.state';
import { StateEffects as TLSW_Effects } from './state/widgets/small/user-adoption/tl.state';
import { StateEffects as AULW_Effects } from './state/widgets/large/user-adoption/au.state';
import { StateEffects as TLLW_Effects } from './state/widgets/large/user-adoption/tl.state';
import { StateEffects as AUSF_LW_Effects } from './state/widgets/large/user-adoption-sf/au.state';
import { StateEffects as TLSF_LW_Effects } from './state/widgets/large/user-adoption-sf/tl.state';

// Catalog
import { StateEffects as C_SW_NOC_Effects } from './state/widgets/small/catalog/noc.state';
import { StateEffects as C_SW_CC_Effects } from './state/widgets/small/catalog/cc.state';
import { StateEffects as C_SW_CV_Effects } from './state/widgets/small/catalog/cv.state';
import { StateEffects as C_SW_CI_Effects } from './state/widgets/small/catalog/ci.state';
import { StateEffects as C_SW_CISSUES_Effects } from './state/widgets/small/catalog/issues.state';
import { StateEffects as C_LW_NOC_Effects } from './state/widgets/large/catalog/noc.state';
import { StateEffects as C_LW_CC_Effects } from './state/widgets/large/catalog/cc.state';
import { StateEffects as C_LW_CIEffects } from './state/widgets/large/catalog/ci.state';
import { StateEffects as C_LW_CVEffects } from './state/widgets/large/catalog/cv.state';
import { StateEffects as C_LW_ISSUESEffects } from './state/widgets/large/catalog/issues.state';

// Performance
import { StateEffects as PF_SW_CST_Effects } from './state/widgets/small/performance/cst.state';
import { StateEffects as PF_SW_SST_Effects } from './state/widgets/small/performance/sst.state';
import { StateEffects as PF_SW_PI_Effects } from './state/widgets/small/performance/pi.state';
import { StateEffects as PF_LW_CST_Effects } from './state/widgets/large/performance/cst.state';
import { StateEffects as PF_LW_SST_Effects } from './state/widgets/large/performance/sst.state';
import { StateEffects as PF_LW_PI_Effects } from './state/widgets/large/performance/pi.state';

// Integrations
import { StateEffects as INTLW_Effects } from './state/widgets/large/integrations/int.state';

// Alerts
import { StateEffects as AL_SW_AI_Effects } from './state/widgets/small/alerts/ai.state';
import { StateEffects as AL_SW_AT_Effects } from './state/widgets/small/alerts/at.state';
import { StateEffects as AL_LW_AI_Effects } from './state/widgets/large/alerts/ai.state';
import { StateEffects as AL_LW_AT_Effects } from './state/widgets/large/alerts/at.state';

// Duplicated code
import { StateEffects as DC_SW_DC_Effects } from './state/widgets/small/duplicated-code/dc.state';
import { StateEffects as DC_LW_DC_Effects } from './state/widgets/large/duplicated-code/dc.state';

// Licensing
import { StateEffects as LI_SW_LI_Effects } from './state/widgets/small/licensing/licenses.state';
import { StateEffects as LI_SW_GLS_Effects } from './state/widgets/small/licensing/gls.state';
import { StateEffects as LI_SW_GLAC_Effects } from './state/widgets/small/licensing/glac.state';
import { StateEffects as LI_LW_LI_Effects } from './state/widgets/large/licensing/licenses.state';
import { StateEffects as LI_LW_GLS_Effects } from './state/widgets/large/licensing/gls.state';
import { StateEffects as LI_LW_GLAC_Effects } from './state/widgets/large/licensing/glac.state';

// Cross Org Licensing
import { StateEffects as COL_SW_TL_Effects } from './state/widgets/small/cross-org-licensing/tl.state';
import { StateEffects as COL_LW_TL_Effects } from './state/widgets/large/cross-org-licensing/tl.state';

// Release Management
import { StateEffects as RM_SW_RM_Effects } from './state/widgets/small/release-management/rm.state';
import { StateEffects as RM_LW_RM_Effects } from './state/widgets/large/release-management/rm.state';
import { StateEffects as RM_LW_APPL_Effects } from './state/widgets/large/release-management/appl.state';

// Impact Analysis
import { StateEffects as IA_LW_IA_Effects } from './state/widgets/large/impact-analysis/ia/ia.state';

// Feature Branch
import { StateEffects as FB_LW_FB_Effects } from './state/widgets/large/feature-branch/fb.state';

// Notifications
import { NotificationsEffects } from './state/notifications/notifications.effects';

// Download XLS
import { DownloadFormattedFileEffects } from './state/download-formatted-file/download-formatted-file.effects';

// Jira Integration
import { JiraIntegrationEffects } from './state/jira-integration/jira-integration.effects';

// Write Offs
import { StateEffects as WO_LW_WO_Effects } from './state/widgets/large/write-offs/wo.state';

// Debt Manager
import { StateEffects as DM_LW_DM_Effects } from './state/widgets/large/debt-manager/dm.state';
import { StateEffects as DM_LW_DMCWO_Effects } from './state/widgets/large/debt-manager/dm-closed-wo.state';
import { StateEffects as DM_LW_DMCF_Effects } from './state/widgets/large/debt-manager/dm-closed-fixed.state';

import { TagsEffects } from './state/tags/tags.effects';
import { TwoFactorAuthenticationEffects } from '../shared/two-factor-authentication/two-factor-authentication.effects';
import { ActivityTrackingEffects } from './state/activity-tracking/activity-tracking.effects';

export {
    TitleService,
    routeAnimations,
    AppState,
    LocalStorageService,
    ROUTE_ANIMATIONS_ELEMENTS,
    AnimationsService,
    selectRouterState,
    NotificationService,
    selectEffectiveTheme,
    selectSettingsLanguage,
    selectSettingsStickyHeader
};

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(
        http,
        `${environment.i18nPrefix}/assets/i18n/`,
        '.json'
    );
}

@NgModule({
    imports: [
        // angular
        CommonModule,
        HttpClientModule,
        // ngrx
        StoreModule.forRoot(reducers, { metaReducers }),
        StoreRouterConnectingModule.forRoot(),
        EffectsModule.forRoot([
            // AuthEffects,
            QCAuthEffects,
            GeneralEffects,
            NotificationsEffects,
            TwoFactorAuthenticationEffects,
            JiraIntegrationEffects,
            ActivityTrackingEffects,
            TagsEffects,
            DownloadFormattedFileEffects,
            // usage
            U_SW_UA_Effects,
            U_SW_LI_Effects,
            U_SW_GLS_Effects,
            U_SW_GLAC_Effects,
            U_LW_UA_Effects,
            U_LW_LI_Effects,
            U_LW_GLS_Effects,
            U_LW_GLAC_Effects,
            // livecheck overview widgets
            UsageSW_Effects,
            UsageLW_Effects,
            ED_SW_Effects,
            ED_LW_Effects,
            Rm_App_SW_Effects,
            Rm_Hist_SW_Effects,
            Fb_SW_Effects,
            // others
            SettingsEffects,
            ScanIssuesEffects,
            // governance
            SFSW_Effects,
            SNSW_Effects,
            ALSW_Effects,
            SFLW_Effects,
            SNLW_Effects,
            USFSW_Effects,
            USNSW_Effects,
            // Asset Manager
            AMLW_Effects,
            // Profiling
            // Compare Multiple Instances
            CMILW_Effects,
            INTSW_Effects,
            // Most Configurable Areas
            MCALW_Effects,
            // User Adoption
            AUSW_Effects,
            TLSW_Effects,
            AULW_Effects,
            TLLW_Effects,
            AUSF_LW_Effects,
            TLSF_LW_Effects,
            // Catalog
            C_SW_NOC_Effects,
            C_SW_CC_Effects,
            C_SW_CV_Effects,
            C_SW_CI_Effects,
            C_SW_CISSUES_Effects,
            C_LW_NOC_Effects,
            C_LW_CC_Effects,
            C_LW_CIEffects,
            C_LW_CVEffects,
            C_LW_ISSUESEffects,
            // Licensing
            LI_SW_LI_Effects,
            LI_SW_GLS_Effects,
            LI_SW_GLAC_Effects,
            LI_LW_LI_Effects,
            LI_LW_GLS_Effects,
            LI_LW_GLAC_Effects,
            // Cross Org Licensing
            COL_SW_TL_Effects,
            COL_LW_TL_Effects,
            // Performance
            PF_SW_CST_Effects,
            PF_SW_SST_Effects,
            PF_SW_PI_Effects,
            PF_LW_CST_Effects,
            PF_LW_SST_Effects,
            PF_LW_PI_Effects,
            // Customization vs configuration
            CVSC_SW_CR_Effects,
            CVSC_LW_CR_Effects,
            // Integrations
            INTLW_Effects,
            // Alerts
            AL_SW_AI_Effects,
            AL_SW_AT_Effects,
            AL_LW_AI_Effects,
            AL_LW_AT_Effects,
            // Duplicated code
            DC_SW_DC_Effects,
            DC_LW_DC_Effects,
            // Release management
            RM_SW_RM_Effects,
            RM_LW_RM_Effects,
            RM_LW_APPL_Effects,
            // Impact Analysis
            IA_LW_IA_Effects,
            // Feature Branch
            FB_LW_FB_Effects,
            // Write Offs
            WO_LW_WO_Effects,
            // Debt Manager
            DM_LW_DM_Effects,
            DM_LW_DMCWO_Effects,
            DM_LW_DMCF_Effects
        ]),
        /*    environment.production
            ? []
            : */ StoreDevtoolsModule.instrument(
            {
                name: 'Quality clouds BI'
            }
        ),

        // 3rd party
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        QCModule
    ],
    declarations: [],
    providers: [
        DatePipe,
        DecimalPipe,
        WidgetService,
        { provide: RouterStateSerializer, useClass: CustomSerializer },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: QCAuthInterceptor,
            multi: true
        }
    ],
    exports: [TranslateModule, QCModule]
})
export class CoreModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: CoreModule
    ) {
        if (parentModule) {
            throw new Error(
                'CoreModule is already loaded. Import only in AppModule'
            );
        }
    }
}
