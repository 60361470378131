import { Actions, ofType } from '@ngrx/effects';
import { WidgetService } from '../../services/widget.service';
import { ActionCreator, Store } from '@ngrx/store';
import { AppState } from '../../core.state';
import { EMPTY, Observable, of } from 'rxjs';
import {
    catchError,
    finalize,
    map,
    mergeMap,
    switchMap,
    withLatestFrom
} from 'rxjs/operators';
import {
    baseFilterSelector,
    dashboardSelector,
    filtersSelector,
    includeBaselineSelector,
    instanceIdSelector,
    instanceSelector,
    lastScansSelector,
    mainFilterSelector,
    scanIdSelector,
    scanLongIdSelector,
    scanSelector,
    selectedDateRangeSelector,
    serviceIdSelector,
    showDateSelect,
    tablesAndDictionariesSelector
} from '../general/general.selectors';
import { HttpErrorResponse } from '@angular/common/http';
import { GeneralActions } from '../general/general.actions';
import { Injectable } from '@angular/core';
import { composeCascadeFilters } from '../../../utils/widget-state-creator';
import { getYYYYMMDDfromDate } from '../../../utils/general';
import { SatDatepickerRangeValue } from 'saturn-datepicker';
import { Router } from '@angular/router';
import {
    OFFICE_ID,
    SALESFORCE_ID,
    SERVICENOW_ID
} from '../../../../utils/constants';
import { customerIdSelector } from '../../auth-lib';

@Injectable()
export abstract class WidgetEffects<T> {
    protected loadFromLatestOrSelected = this.actions$.pipe(
        ofType(this.actions.load),
        withLatestFrom(this.store.select(scanSelector)),
        withLatestFrom(this.store.select(lastScansSelector)),
        switchMap(([[{}, scan], lastScans]) => {
            return this.service
                .load(
                    this.params.load.path,
                    scan && scan['service-id'] === this.params.load.service
                        ? scan['short-id']
                        : lastScans[this.params.load.serviceName].attributes[
                              'short-id'
                          ],
                    undefined,
                    undefined,
                    undefined
                )
                .pipe(
                    map((data: T) => this.actions.success({ data })),
                    catchError((error: HttpErrorResponse) =>
                        of(this.actions.error({ error: error.message }))
                    )
                );
        })
    );
    protected loadCompactByInstance = this.actions$.pipe(
        ofType(this.actions.load),
        withLatestFrom(
            this.store.select(scanSelector),
            this.store.select(lastScansSelector)
        ),
        switchMap(([{}, scan, lastScans]) => {
            return this.service
                .load(
                    this.params.load.path,
                    scan && scan['service-id'] === this.params.load.service
                        ? scan['instance-id']
                        : lastScans[this.params.load.serviceName].attributes[
                              'instance-id'
                          ],
                    undefined,
                    undefined,
                    undefined
                )
                .pipe(
                    map((data: T) => this.actions.success({ data })),
                    catchError((error: HttpErrorResponse) =>
                        of(this.actions.error({ error: error.message }))
                    )
                );
        })
    );
    protected load = this.actions$.pipe(
        ofType(this.actions.load),
        withLatestFrom(
            this.store.select(scanIdSelector),
            this.store.select(showDateSelect),
            this.store.select(selectedDateRangeSelector),
            this.store.select(instanceIdSelector),
            this.store.select(serviceIdSelector),
            this.store.select(mainFilterSelector),
            this.store.select(dashboardSelector),
            this.store.select(includeBaselineSelector),
            this.store.select(tablesAndDictionariesSelector)
        ),
        switchMap(
            ([
                {},
                scanId,
                isDateDashboard,
                dateRange,
                instanceId,
                serviceId,
                mainFilter,
                dashboard,
                includeBaseline,
                includeTablesAndDict
            ]) => {
                let defaultInitialDate;
                let filter;
                if (isDateDashboard && dateRange === null) {
                    defaultInitialDate = this.getInitialDate();
                }
                if (this.params.load.instanceOrService && instanceId === null) {
                    if (this.router.url.includes('servicenow')) {
                        filter = { providerId: SERVICENOW_ID };
                    } else if (this.router.url.includes('salesforce')) {
                        filter = { providerId: SALESFORCE_ID };
                    }
                }
                if (this.params.providerParam) {
                    filter = { ...filter, providerId: serviceId };
                }
                return this.service
                    .load(
                        this.params.load.path,
                        isDateDashboard ||
                            this.isNoDateNoOperationalScanDashboard(
                                this.params.load.path
                            )
                            ? instanceId
                            : scanId,
                        filter,
                        undefined,
                        isDateDashboard
                            ? {
                                  begin: getYYYYMMDDfromDate(
                                      dateRange
                                          ? dateRange.begin
                                          : defaultInitialDate.begin
                                  ),
                                  end: getYYYYMMDDfromDate(
                                      dateRange
                                          ? dateRange.end
                                          : defaultInitialDate.end
                                  )
                              }
                            : undefined,
                        this.params.mainFilter ? mainFilter : undefined,
                        dashboard.url !== 'executive'
                            ? undefined
                            : this.params.load.alwaysBaseline
                            ? true
                            : includeBaseline,
                        dashboard.url !== 'upgradeability'
                            ? undefined
                            : includeTablesAndDict
                    )
                    .pipe(
                        map((data: T) => this.actions.success({ data })),
                        catchError((error: HttpErrorResponse) =>
                            of(this.actions.error({ error: error.message }))
                        )
                    );
            }
        )
    );
    protected scanReset = this.actions$.pipe(
        ofType(
            GeneralActions.setScanSuccess,
            GeneralActions.setMainFilter,
            GeneralActions.toggleIncludeBaseline,
            GeneralActions.setIncludeBaseline,
            GeneralActions.toggleIncludeTablesAndDict,
            GeneralActions.setIncludeTablesAndDict
        ),
        withLatestFrom(this.store.select(this.stateSelector)),
        switchMap(([{}, store]) => {
            return store.isLoaded ? of(this.actions.reset()) : EMPTY;
        })
    );

    protected dateChangedReset = this.actions$.pipe(
        ofType(GeneralActions.setDashboardDateRange),
        withLatestFrom(this.store.select(this.stateSelector)),
        switchMap(([{}, store]) => {
            return store.isLoaded ? of(this.actions.reset()) : EMPTY;
        })
    );
    protected instanceReset = this.actions$.pipe(
        ofType(
            GeneralActions.instanceLoadSuccess,
            GeneralActions.resetInstance
        ),
        withLatestFrom(this.store.select(this.stateSelector)),
        switchMap(([{}, store]) =>
            store.isLoaded ? of(this.actions.reset()) : EMPTY
        )
    );

    protected loadLarge = this.actions$.pipe(
        ofType(this.actions.load),
        withLatestFrom(
            this.store.select(serviceIdSelector),
            this.store.select(instanceSelector),
            this.store.select(scanIdSelector),
            this.store.select(scanLongIdSelector),
            this.store.select(showDateSelect),
            this.store.select(selectedDateRangeSelector),
            this.store.select(customerIdSelector),
            this.store.select(mainFilterSelector),
            this.store.select(dashboardSelector),
            this.store.select(scanSelector),
            this.store.select(includeBaselineSelector),
            this.store.select(tablesAndDictionariesSelector)
        ),
        switchMap(
            ([
                { filter, tableFilter },
                serviceId,
                instance,
                scanId,
                scanLongId,
                isDateDashboard,
                dateRange,
                customerId,
                mainFilter,
                dashboard,
                scan,
                includeBaseline,
                includeTablesAndDict
            ]) => {
                let defaultInitialDate;
                if (isDateDashboard && dateRange === null) {
                    defaultInitialDate = this.getInitialDate();
                }
                if (
                    this.params.loadLarge.instanceOrService &&
                    (instance === null || instance.id === null)
                ) {
                    if (this.router.url.includes('servicenow')) {
                        filter = { ...filter, providerId: SERVICENOW_ID };
                    } else if (this.router.url.includes('salesforce')) {
                        filter = { ...filter, providerId: SALESFORCE_ID };
                    }
                }
                if (this.params.providerParam) {
                    filter = { ...filter, providerId: serviceId };
                }
                return this.service
                    .loadLarge(this.params.loadLarge, {
                        serviceId,
                        instanceId: instance ? instance.id : null,
                        scanId,
                        scanLongId,
                        filter: this.params.mainFilter
                            ? { ...filter, condition: mainFilter }
                            : filter,
                        tableFilter,
                        timeFilter: isDateDashboard
                            ? {
                                  begin: getYYYYMMDDfromDate(
                                      dateRange
                                          ? dateRange.begin
                                          : defaultInitialDate.begin
                                  ),
                                  end: getYYYYMMDDfromDate(
                                      dateRange
                                          ? dateRange.end
                                          : defaultInitialDate.end
                                  )
                              }
                            : undefined,
                        customerId,
                        providerId: instance ? instance.serviceId : null,
                        includeBaseline:
                            dashboard.url !== 'executive'
                                ? undefined
                                : this.params.loadLarge.alwaysBaseline
                                ? true
                                : includeBaseline,
                        scan,
                        includeTablesAndDict:
                            dashboard.url !== 'upgradeability'
                                ? undefined
                                : includeTablesAndDict
                    })
                    .pipe(
                        map((data: any) => {
                            return this.actions.success({ data });
                        }),
                        finalize(() => {
                            if (this.actions.complete) {
                                this.store.dispatch(this.actions.complete());
                            }
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                this.actions.error({ error: error.message })
                            );
                        })
                    );
            }
        )
    );
    protected loadLargeWidget = this.actions$.pipe(
        ofType(this.actions.load),
        withLatestFrom(
            this.store.select(serviceIdSelector),
            this.store.select(instanceSelector),
            this.store.select(scanIdSelector),
            this.store.select(scanLongIdSelector),
            this.store.select(showDateSelect),
            this.store.select(selectedDateRangeSelector),
            this.store.select(customerIdSelector),
            this.store.select(mainFilterSelector),
            this.store.select(dashboardSelector),
            this.store.select(scanSelector),
            this.store.select(includeBaselineSelector),
            this.store.select(tablesAndDictionariesSelector)
        ),
        switchMap(
            ([
                { filter, tableFilter },
                serviceId,
                instance,
                scanId,
                scanLongId,
                isDateDashboard,
                dateRange,
                customerId,
                mainFilter,
                dashboard,
                scan,
                includeBaseline,
                includeTablesAndDict
            ]) => {
                let defaultInitialDate;
                if (isDateDashboard && dateRange === null) {
                    defaultInitialDate = this.getInitialDate();
                }
                if (
                    this.params.loadLargeWidget.instanceOrService &&
                    (instance === null || instance.id === null)
                ) {
                    if (this.router.url.includes('servicenow')) {
                        filter = { ...filter, providerId: SERVICENOW_ID };
                    } else if (this.router.url.includes('salesforce')) {
                        filter = { ...filter, providerId: SALESFORCE_ID };
                    }
                }
                if (this.params.providerParam) {
                    filter = { ...filter, providerId: serviceId };
                }
                return this.service
                    .loadLarge(this.params.loadLargeWidget, {
                        serviceId,
                        instanceId: instance ? instance.id : null,
                        scanId,
                        scanLongId,
                        filter: this.params.mainFilter
                            ? { ...filter, condition: mainFilter }
                            : filter,
                        tableFilter,
                        timeFilter: isDateDashboard
                            ? {
                                  begin: getYYYYMMDDfromDate(
                                      dateRange
                                          ? dateRange.begin
                                          : defaultInitialDate.begin
                                  ),
                                  end: getYYYYMMDDfromDate(
                                      dateRange
                                          ? dateRange.end
                                          : defaultInitialDate.end
                                  )
                              }
                            : undefined,
                        customerId,
                        providerId: instance ? instance.serviceId : null,
                        includeBaseline:
                            dashboard.url !== 'executive'
                                ? undefined
                                : this.params.loadLargeWidget.alwaysBaseline
                                ? true
                                : includeBaseline,
                        scan,
                        includeTablesAndDict:
                            dashboard.url !== 'upgradeability'
                                ? undefined
                                : includeTablesAndDict
                    })
                    .pipe(
                        map((data: any) => {
                            return this.actions.success({
                                data,
                                loadType: 'widget'
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                this.actions.error({ error: error.message })
                            );
                        })
                    );
            }
        )
    );
    protected loadLargeTable = this.actions$.pipe(
        ofType(this.actions.load),
        withLatestFrom(this.store.select(serviceIdSelector)),
        withLatestFrom(this.store.select(instanceSelector)),
        withLatestFrom(this.store.select(scanIdSelector)),
        withLatestFrom(this.store.select(scanLongIdSelector)),
        withLatestFrom(this.store.select(showDateSelect)),
        withLatestFrom(this.store.select(selectedDateRangeSelector)),
        withLatestFrom(this.store.select(customerIdSelector)),
        withLatestFrom(this.store.select(mainFilterSelector)),
        withLatestFrom(this.store.select(dashboardSelector)),
        withLatestFrom(this.store.select(scanSelector)),
        withLatestFrom(this.store.select(includeBaselineSelector)),
        withLatestFrom(this.store.select(tablesAndDictionariesSelector)),
        switchMap(
            ([
                [
                    [
                        [
                            [
                                [
                                    [
                                        [
                                            [
                                                [
                                                    [
                                                        [
                                                            {
                                                                filter,
                                                                tableFilter
                                                            },
                                                            serviceId
                                                        ],
                                                        instance
                                                    ],
                                                    scanId
                                                ],
                                                scanLongId
                                            ],
                                            isDateDashboard
                                        ],
                                        dateRange
                                    ],
                                    customerId
                                ],
                                mainFilter
                            ],
                            dashboard
                        ],
                        scan
                    ],
                    includeBaseline
                ],
                includeTablesAndDict
            ]) => {
                let defaultInitialDate;
                if (isDateDashboard && dateRange === null) {
                    defaultInitialDate = this.getInitialDate();
                }
                if (
                    this.params.loadLargeTable.instanceOrService &&
                    (instance === null || instance.id === null)
                ) {
                    if (this.router.url.includes('servicenow')) {
                        filter = { ...filter, providerId: SERVICENOW_ID };
                    } else if (this.router.url.includes('salesforce')) {
                        filter = { ...filter, providerId: SALESFORCE_ID };
                    }
                }
                if (this.params.providerParam) {
                    filter = { ...filter, providerId: serviceId };
                }
                return this.service
                    .loadLarge(this.params.loadLargeTable, {
                        serviceId,
                        instanceId: instance ? instance.id : null,
                        scanId,
                        scanLongId,
                        filter: this.params.mainFilter
                            ? { ...filter, condition: mainFilter }
                            : filter,
                        tableFilter,
                        timeFilter: isDateDashboard
                            ? {
                                  begin: getYYYYMMDDfromDate(
                                      dateRange
                                          ? dateRange.begin
                                          : defaultInitialDate.begin
                                  ),
                                  end: getYYYYMMDDfromDate(
                                      dateRange
                                          ? dateRange.end
                                          : defaultInitialDate.end
                                  )
                              }
                            : undefined,
                        customerId,
                        providerId: instance ? instance.serviceId : null,
                        includeBaseline:
                            dashboard.url !== 'executive'
                                ? undefined
                                : this.params.loadLargeTable.alwaysBaseline
                                ? true
                                : includeBaseline,
                        scan,
                        includeTablesAndDict:
                            dashboard.url !== 'upgradeability'
                                ? undefined
                                : includeTablesAndDict
                    })
                    .pipe(
                        map((data: any) => {
                            return this.actions.success({
                                data,
                                loadType: 'grid'
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                this.actions.error({ error: error.message })
                            );
                        })
                    );
            }
        )
    );
    protected loadDetails = this.actions$.pipe(
        ofType(this.actions.loadDetails),
        withLatestFrom(this.store.select(serviceIdSelector)),
        withLatestFrom(this.store.select(instanceIdSelector)),
        withLatestFrom(this.store.select(scanIdSelector)),
        withLatestFrom(this.store.select(scanLongIdSelector)),
        withLatestFrom(this.store.select(showDateSelect)),
        withLatestFrom(this.store.select(selectedDateRangeSelector)),
        withLatestFrom(this.store.select(dashboardSelector)),
        withLatestFrom(this.store.select(tablesAndDictionariesSelector)),
        mergeMap(
            ([
                [
                    [
                        [
                            [
                                [
                                    [
                                        [
                                            { index, filter, tableFilter },
                                            serviceId
                                        ],
                                        instanceId
                                    ],
                                    scanId
                                ],
                                scanLongId
                            ],
                            isDateDashboard
                        ],
                        dateRange
                    ],
                    dashboard
                ],
                includeTablesAndDict
            ]) =>
                this.service
                    .loadDetails(this.params.loadDetails, {
                        serviceId:
                            serviceId !== null && serviceId !== undefined
                                ? serviceId
                                : this.router &&
                                  this.router.url &&
                                  this.router.url.includes('servicenow')
                                ? SERVICENOW_ID
                                : this.router &&
                                  this.router.url &&
                                  this.router.url.includes('salesforce')
                                ? SALESFORCE_ID
                                : undefined,
                        instanceId,
                        scanId,
                        scanLongId,
                        filter,
                        tableFilter,
                        timeFilter: isDateDashboard
                            ? {
                                  begin: getYYYYMMDDfromDate(dateRange.begin),
                                  end: getYYYYMMDDfromDate(dateRange.end)
                              }
                            : undefined,
                        includeTablesAndDict:
                            dashboard.url !== 'upgradeability'
                                ? undefined
                                : includeTablesAndDict
                    })
                    .pipe(
                        map((data: any) =>
                            this.actions.detailsSuccess({
                                data,
                                key: this.getDetailsKey(
                                    this.params.loadDetails.key,
                                    filter
                                )
                            })
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(this.actions.error({ error: error.message }))
                        )
                    )
        )
    );
    protected loadModal = this.actions$.pipe(
        ofType(this.actions.loadModal),
        withLatestFrom(this.store.select(serviceIdSelector)),
        withLatestFrom(this.store.select(instanceIdSelector)),
        withLatestFrom(this.store.select(scanIdSelector)),
        withLatestFrom(this.store.select(scanLongIdSelector)),
        withLatestFrom(this.store.select(showDateSelect)),
        withLatestFrom(this.store.select(selectedDateRangeSelector)),
        switchMap(
            ([
                [
                    [
                        [
                            [[{ filter, tableFilter }, serviceId], instanceId],
                            scanId
                        ],
                        scanLongId
                    ],
                    isDateDashboard
                ],
                dateRange
            ]) =>
                this.service
                    .loadModal(this.params.loadModal, {
                        serviceId:
                            serviceId !== null && serviceId !== undefined
                                ? serviceId
                                : this.router &&
                                  this.router.url &&
                                  this.router.url.includes('servicenow')
                                ? SERVICENOW_ID
                                : this.router &&
                                  this.router.url &&
                                  this.router.url.includes('salesforce')
                                ? SALESFORCE_ID
                                : undefined,
                        instanceId,
                        scanId,
                        scanLongId,
                        filter,
                        tableFilter,
                        timeFilter: isDateDashboard
                            ? {
                                  begin: getYYYYMMDDfromDate(dateRange.begin),
                                  end: getYYYYMMDDfromDate(dateRange.end)
                              }
                            : undefined
                    })
                    .pipe(
                        map((data: any) =>
                            this.actions.modalSuccess({
                                data,
                                key: this.getDetailsKey(
                                    this.params.loadModal.key,
                                    filter
                                )
                            })
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(this.actions.error({ error: error.message }))
                        )
                    )
        )
    );
    protected loadNewCascadeFilters = this.actions$.pipe(
        ofType(this.actions.load),
        withLatestFrom(this.store.select(instanceIdSelector)),
        withLatestFrom(this.store.select(scanIdSelector)),
        withLatestFrom(this.store.select(baseFilterSelector)),
        withLatestFrom(this.store.select(this.stateSelector)),
        switchMap(
            ([[[[{ filter }, instanceId], scanId], baseFilters], store]) => {
                return this.service
                    .newCascadeFilters(
                        this.params.cascadeFilters,
                        filter,
                        instanceId,
                        scanId,
                        this.params.cascadeFiltersContext
                    )
                    .pipe(
                        switchMap((data: any[]) => {
                            const newBaseFilters = {};
                            const newData = data.map(itemFilter => {
                                const newFilter = { ...itemFilter };
                                let newFilterElements;
                                if (
                                    !store.isLoaded ||
                                    !baseFilters[newFilter.filterName] ||
                                    baseFilters[newFilter.filterName]
                                        .context !==
                                        this.params.cascadeFiltersContext
                                ) {
                                    newBaseFilters[newFilter.filterName] = {
                                        context: this.params
                                            .cascadeFiltersContext,
                                        filterValues: newFilter.filterElements
                                    };
                                    newFilterElements =
                                        newFilter.filterElements;
                                } else {
                                    newFilterElements =
                                        baseFilters[newFilter.filterName]
                                            .filterValues;
                                }
                                newFilter.filterElements = newFilterElements.reduce(
                                    (result: any[], itemFilterElement) => {
                                        const newFilterElement = {
                                            ...itemFilterElement
                                        };
                                        if (
                                            newFilter.filterElements.find(
                                                el =>
                                                    el.value ===
                                                        itemFilterElement.value &&
                                                    el.label ===
                                                        itemFilterElement.label
                                            )
                                        ) {
                                            result.unshift(newFilterElement);
                                        } else {
                                            newFilterElement.disabled = true;
                                            result.push(newFilterElement);
                                        }
                                        return result;
                                    },
                                    []
                                );
                                return newFilter;
                            });
                            if (Object.keys(newBaseFilters).length > 0)
                                return [
                                    this.actions.saveCascadeFilters({
                                        data: newData
                                    }),
                                    GeneralActions.saveBaseFilters({
                                        baseFilters: newBaseFilters
                                    })
                                ];
                            return [
                                this.actions.saveCascadeFilters({
                                    data: newData
                                })
                            ];
                        })
                    );
            }
        )
    );
    protected loadCascadeFilters = this.actions$.pipe(
        ofType(this.actions.load),
        withLatestFrom(
            this.store.select(scanIdSelector),
            this.store.select(filtersSelector),
            this.store.select(showDateSelect),
            this.store.select(selectedDateRangeSelector),
            this.store.select(instanceIdSelector),
            this.store.select(serviceIdSelector),
            this.store.select(mainFilterSelector),
            this.store.select(dashboardSelector),
            this.store.select(includeBaselineSelector),
            this.store.select(tablesAndDictionariesSelector)
        ),
        switchMap(
            ([
                { filter },
                scanId,
                generalFilters,
                show,
                dateRange,
                instanceId,
                serviceId,
                mainFilter,
                dashboard,
                includeBaseline,
                includeTablesAndDict
            ]) => {
                const copyFilter =
                    dashboard.url !== 'upgradeability'
                        ? this.params.mainFilter
                            ? {
                                  ...filter,
                                  condition: mainFilter,
                                  includeBaseline
                              }
                            : { ...filter, includeBaseline }
                        : this.params.mainFilter
                        ? {
                              ...filter,
                              condition: mainFilter,
                              includeBaseline,
                              includeTablesAndDict
                          }
                        : {
                              ...filter,
                              includeBaseline,
                              includeTablesAndDict
                          };

                if (
                    copyFilter.hasOwnProperty('bestPracticeEngine') &&
                    serviceId === SERVICENOW_ID
                ) {
                    delete copyFilter['bestPracticeEngine'];
                    copyFilter['groupIssueType'] = 'Data Privacy';
                }
                if (copyFilter.hasOwnProperty('tags')) {
                    delete copyFilter['tags'];
                }
                if (
                    copyFilter.hasOwnProperty('active') &&
                    (copyFilter['active'] === '' ||
                        copyFilter['active'] === undefined)
                ) {
                    delete copyFilter['active'];
                }
                return this.service
                    .cascadeFilters(
                        scanId,
                        serviceId === OFFICE_ID
                            ? this.params.cascadeFilters.filter(
                                  f => f !== 'namespaces'
                              )
                            : this.params.cascadeFilters,
                        copyFilter,
                        show,
                        dateRange,
                        instanceId,
                        this.router && this.router.url.includes('servicenow')
                            ? SERVICENOW_ID
                            : this.router &&
                              this.router.url.includes('salesforce')
                            ? SALESFORCE_ID
                            : null
                    )
                    .pipe(
                        map((specificFilters: any) => {
                            const data = composeCascadeFilters(
                                generalFilters,
                                specificFilters
                            );
                            return this.actions.saveCascadeFilters({ data });
                        }),
                        catchError((error: HttpErrorResponse) =>
                            of(this.actions.error({ error: error.message }))
                        )
                    );
            }
        )
    );

    protected constructor(
        protected actions$: Actions,
        protected service: WidgetService | any,
        protected store: Store<AppState>,
        protected actions: { [key: string]: ActionCreator<any, any> },
        protected params: any,
        protected stateSelector: any,
        protected calls?: { [key: string]: (...params) => Observable<any> },
        protected router?: Router
    ) {}

    getInitialDate(): SatDatepickerRangeValue<Date> {
        const begin = new Date();
        begin.setMonth(begin.getMonth() - 1);
        const end = new Date();
        return { begin, end } as SatDatepickerRangeValue<Date>;
    }

    isNoDateNoOperationalScanDashboard(path): boolean {
        return [
            'licenses-compact',
            'governor-limit-storage-compact',
            'governor-limit-api-compact'
        ].includes(path);
    }

    private getDetailsKey(param, filter) {
        switch (param) {
            case 'ov-usage':
                return filter.ceName + '_' + filter.developer;
            case 'ov-ed':
                return filter.scanId;
            case 'am-am':
                return filter.AMmainScanId;
            case 'ov-ci':
                return filter.bp_id;
            case 'c-noc':
                return filter.catalogName;
            case 'c-cc':
                return filter.catalogName + filter.categoryName + filter.active;
            case 'dc-dc':
                return filter.blockId;
            case 'rm-lw':
                return filter.updateSetScanId;
            case 'rm-lw-iss':
                return filter.updateSetScanId + '_' + filter.sysId;
            case 'rm-appl-lw':
                return filter.RMApplicationsScanId;
            case 'rm-appl-lw-iss':
                return filter.RMApplicationsScanId + '_' + filter.sysId;
            case 'fb-lw-iss':
                return [
                    filter.featureBranchScanId,
                    filter.ceType,
                    filter.ceName
                ].join('_');
            case 'col-tl':
                return filter.instanceId + '_' + filter.licenseName;
            case 'fb-lw':
                return filter.featureBranchScanId;
            case 'ns-tn':
                return filter.serviceId === SERVICENOW_ID
                    ? filter.sysId
                    : filter.ceType + '-' + filter.ceName;
            case 'cd-cd-iss':
                return filter.serviceId === SERVICENOW_ID
                    ? filter.sysId
                    : filter.ceType + '-' + filter.ceName;
            case 'ov-usage-lw-iss':
                return filter.serviceId === SERVICENOW_ID
                    ? filter.sysId
                    : filter.ceType + '-' + filter.ceName;
            case 'cd-cd':
                return filter['duplicate_block_id'] + filter['not_id'];
            default:
                console.warn(
                    'details key not defined. default instead',
                    filter
                );
                return filter.updatedBy;
        }
    }
}
