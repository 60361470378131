import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UsersService } from '../../../../../../../../core/services/users.service';
import { Observable, of, Subject } from 'rxjs';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ValidationErrors
} from '@angular/forms';
import {
    debounceTime,
    distinctUntilChanged,
    first,
    map,
    scan,
    startWith,
    switchMap,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AppState } from '../../../../../../../../core/core.state';
import { Store } from '@ngrx/store';
import { instanceIdSelector } from '../../../../../../../../core/state/general/general.selectors';
import { selectCurrentUserId } from '../../../../../../../../core/auth-lib';

export type User = {
    id: number;
    username: string;
};

export type UsersData = {
    data: any[];
    nextPage: number;
};

@Component({
    selector: 'qcbi-users-dropdown-search',
    templateUrl: 'users-dropdown-search.component.html',
    styleUrls: ['users-dropdown-search.component.scss']
})
export class UsersDropdownSearchComponent implements OnInit {
    @Input() initialIds: number[];
    @Input() disabled = false;

    @Output() selectedUsersChange = new EventEmitter<User[]>();

    filteredOptions$: Observable<User[]>;
    loading = false;
    selectedUsers: User[] = [];

    formGroup = new FormGroup({
        userSearch: new FormControl('', [
            (_: AbstractControl): ValidationErrors | null => {
                if (this.selectedUsers.length === 0) return { noUsers: true };
                return null;
            }
        ])
    });

    private nextPage$ = new Subject();

    constructor(
        private usersService: UsersService,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        if (this.disabled) {
            this.formGroup.controls.userSearch.disable();
        } else {
            this.filteredOptions$ = this.formGroup
                .get('userSearch')
                .valueChanges.pipe(
                    startWith(''),
                    debounceTime(500),
                    distinctUntilChanged(),
                    map(val =>
                        val && val.hasOwnProperty('username')
                            ? val.username
                            : val
                    ),
                    switchMap(filter => {
                        let currentPage = 1;
                        return this.nextPage$.pipe(
                            startWith(currentPage),
                            withLatestFrom(
                                this.store.select(instanceIdSelector),
                                this.store.select(selectCurrentUserId)
                            ),
                            tap(() => (this.loading = true)),
                            switchMap(([_, instanceId, currentUserId]) =>
                                currentPage === -1 || filter === null
                                    ? of({ data: [], nextPage: -1 })
                                    : this._filter(
                                          filter,
                                          currentPage,
                                          instanceId,
                                          currentUserId
                                      )
                            ),
                            map((data: UsersData) => {
                                currentPage = data.nextPage;
                                return data.data;
                            }),
                            scan((allOptions: User[], newOptions: User[]) =>
                                allOptions.concat(newOptions)
                            ),
                            tap(() => (this.loading = false))
                        );
                    })
                );
        }
        if (this.initialIds?.length) {
            this.store
                .select(instanceIdSelector)
                .pipe(first())
                .subscribe(instanceId => {
                    this.usersService
                        .getUsers(this.initialIds, [], '', 1, instanceId)
                        .pipe(first())
                        .subscribe(res => {
                            this.selectedUsers = res.data;
                            this.formGroup.controls[
                                'userSearch'
                            ].updateValueAndValidity();
                        });
                });
        }
    }

    scrollHandler() {
        this.nextPage$.next();
    }

    optionSelectedHandler(event: MatAutocompleteSelectedEvent) {
        this.selectedUsers.push(event.option.value as User);
        this.formGroup.patchValue({
            userSearch: ''
        });
        this.selectedUsersChange.emit(this.selectedUsers);
    }

    displayFn(user: User): string {
        return user && user.username ? user.username : '';
    }

    removeFromList(id: number) {
        this.selectedUsers = this.selectedUsers.filter(u => u.id !== id);
        if (this.selectedUsers.length === 0)
            this.formGroup.controls['userSearch'].updateValueAndValidity();
        this.selectedUsersChange.emit(this.selectedUsers);
        this.formGroup.patchValue({
            userSearch: ''
        });
    }

    private _filter(
        search = '',
        currentPage: number,
        instanceId: number,
        currentUserId: string
    ): Observable<UsersData> {
        const filterValue = search.toLowerCase();
        return this.usersService.getUsers(
            [],
            this.selectedUsers.map(u => u.id).concat(+currentUserId),
            filterValue,
            currentPage,
            instanceId
        );
    }
}
