import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { parseDashboardContext } from '../../utils/general';
import {
    ALL_CLOUDS,
    OFFICE_ID,
    SALESFORCE_ID,
    SERVICENOW_ID
} from '../../../utils/constants';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    cachedDashboards = {};

    constructor(private http: HttpClient) {}

    getDashboards(instanceId: number): Observable<any> {
        // TODO: rename to dashboards by instance
        if (this.cachedDashboards.hasOwnProperty(instanceId)) {
            return of(this.cachedDashboards[instanceId]);
        }
        const dashboardsUrl = `${environment.apiUrl}/v2/dashboard?filter[instance_id]=${instanceId}`;
        return this.http.get(dashboardsUrl).pipe(
            map((dashboards: any) => dashboards.data.map(this.parseDashboard)),
            tap(
                dashboardsData =>
                    (this.cachedDashboards[instanceId] = dashboardsData)
            )
        );
    }

    getAllCloudsDashboards(): Observable<{ allCloudsDashboards: any }> {
        return this.getDashboardsByContext(ALL_CLOUDS).pipe(
            map(allCloudsDashboards => {
                return {
                    allCloudsDashboards
                };
            })
        );
    }

    private getDashboardsByContext(contextId: number): Observable<any> {
        const dashboardsUrl = `${environment.apiUrl}/v2/dashboard?filter[dashboard_context]=${contextId}`;
        return this.http.get(dashboardsUrl).pipe(
            map((dashboards: any) => dashboards.data.map(this.parseDashboard)),
            catchError(() => [])
        );
    }

    getDashboardContext(): Observable<any> {
        const url = `${environment.apiDevEndPoint}api/web/dashboard-context`;
        return this.http.get(url).pipe(
            map((data: any) => parseDashboardContext(data.data)),
            catchError(() =>
                of(
                    [SERVICENOW_ID, SALESFORCE_ID, OFFICE_ID, ALL_CLOUDS].map(
                        serviceId => ({
                            serviceId,
                            available: false
                        })
                    )
                )
            )
        );
    }

    private parseDashboard = (dashboard: any) => ({
        id: parseInt(dashboard.id, 10),
        ...dashboard.attributes,
        ...dashboard.meta
    });
}
