<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  (menuOpened)="menuOpenedHandler()"
  style="margin-right: 8px; margin-top: 2px"
  role="button"
  aria-label="notifications"
>
  <mat-icon
    style="color: #333"
    [matBadge]="counter$ | async"
    matBadgeColor="warn"
  >notifications</mat-icon
  >
</button>
<mat-menu class="notifications-panel" #menu="matMenu">
  <ng-template matMenuContent>
      <div class="menu-header">
          <div class="menu-title">Notifications</div>
          <button
              mat-icon-button
              (click)="stopPropagation($event)"
              [matMenuTriggerFor]="notificationsMenu"
              aria-label="notifications menu"
          >
              <mat-icon>more_vert</mat-icon>
          </button>
      </div>
      <mat-menu #notificationsMenu="matMenu">
          <button
              (click)="stopPropagation($event); markAllNotificationsAsRead()"
              mat-menu-item
          >
              <span>Mark all as read</span>
          </button>
          <!-- <button mat-menu-item (click)="navigateToNotifications()">
            <span>Manage notifications</span>
          </button> -->
      </mat-menu>
      <div
          class="items-container"
          *ngIf="notifications$ | async as notifications"
          (scroll)="notifications.length < totalItems && scrollHandler($event, notifications[notifications.length - 1])"
      >
          <div
              *ngIf="notifications.length === 0; else menuItems"
              style="padding: 20px; text-align: center;"
          >
              There are no notifications to display
          </div>
          <ng-template #menuItems>
              <div
                  class="menu-item"
                  (click)="stopPropagation($event)"
                  [ngClass]="{ 'item-unread': !n.isRead }"
                  *ngFor="let n of notifications; let last = last"
              >
                  <div class="flex">
                      <div class="icon-wrapper">
                          <mat-icon>cloud_upload</mat-icon>
                      </div>
                      <div class="w-full">
                          <div class="flex justify-between mb-2">
                              <div class="menu-item-title">
                                  <div
                                      (click)="markNotificationAsRead(n,n.id); notificationClickedHandler(n)"
                                      class="action-title"
                                      matTooltip="Go to instance executive dashboard"
                                  >
                                      Scan Notification
                                  </div>
                                  <div class="date-wrapper">{{ n.date }}</div>
                                  <div class="unread" *ngIf="!n.isRead"></div>
                              </div>
                              <div
                                  *ngIf="!n.isRead"
                                  class="mark-as-read"
                                  (click)="stopPropagation($event); markNotificationAsRead(n,n.id)"
                              >
                                  mark as read
                              </div>
                          </div>
                          <div>{{ n.message }}</div>
                      </div>
                  </div>
                  <!--        <div class="action">-->
                  <!--          <div-->
                  <!--            class="action-text"-->
                  <!--            -->
                  <!--          >-->
                  <!--            Go to instance executive dashboard-->
                  <!--          </div>-->
                  <!--        </div>-->
              </div>
          </ng-template>
      </div>
      <div *ngIf="loading$ | async" class="spinner-container">
          <mat-spinner [diameter]="20"></mat-spinner>
      </div>
  </ng-template>
</mat-menu>
