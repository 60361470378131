import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '../../core/core.state';
import { Store } from '@ngrx/store';
import { GeneralActions } from '../../core/state/general/general.actions';
import { Observable, of, Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatDialog } from '@angular/material/dialog';
import {
    allCloudsDashboardSelector,
    allCloudsSelector,
    dashboardContextSelector,
    instanceIdSelector,
    serviceIdSelector,
    settingsSelector,
    sideMenuExpandedSelector
} from '../../core/state/general/general.selectors';
import { HelpDialogComponent } from '../help-dialog/help-dialog.component';
import {
    sideBarSelector,
    starredSideBarSelector
} from '../../core/state/general/sidebar.selector';
import { HotkeysService } from '../hotkeys/hotkeys.service';
import { DashboardService } from '../../core/services/dashboard.service';

interface InstanceNode {
    instanceId?: number;
    serviceId?: number;
    id?: number;
    type: string;
    name: string;
    icon?: string;
    image?: string;
    children?: InstanceNode[];
    level: number;
}

const GetChildren = (node: InstanceNode) => of(node.children);

@Component({
    selector: 'qcbi-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit, OnDestroy {
    dataSource = {
        top: [],
        services: [],
        bottom: []
    };
    originalDataSource = {
        top: [],
        services: [],
        bottom: []
    };
    tc = new NestedTreeControl(GetChildren);
    tc2 = new NestedTreeControl(GetChildren);

    dataSourceStarred$: Observable<any>;
    instanceId$: Observable<number | undefined>;
    serviceId$: Observable<number | undefined>;
    onAllClouds$: Observable<boolean>;
    expanded$: Observable<boolean>;

    private lastSelectedGroups = [];
    private unsubscribe$ = new Subject<void>();

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private dashboardService: DashboardService,
        private dialog: MatDialog,
        private hotkeysService: HotkeysService,
        private cdr: ChangeDetectorRef
    ) {}

    hasChild(_: number, node: InstanceNode) {
        return node.children !== undefined;
    }

    ngOnInit() {
        this.expanded$ = this.store.select(sideMenuExpandedSelector);
        this.onAllClouds$ = this.store.select(allCloudsSelector);
        this.instanceId$ = this.store.select(instanceIdSelector);
        this.serviceId$ = this.store.select(serviceIdSelector);
        this.dataSourceStarred$ = this.store.select(starredSideBarSelector);
        this.expanded$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(expanded => {
                if (!expanded) {
                    this.lastSelectedGroups = this.tc.expansionModel.selected.reduce(
                        (result, item) =>
                            item.type === 'group' ? [...result, item] : result,
                        []
                    );
                    this.tc.collapseAll();
                } else if (this.lastSelectedGroups.length > 0) {
                    for (let i = 0; i < this.lastSelectedGroups.length; i++) {
                        this.tc.expand(this.lastSelectedGroups[i]);
                    }
                }
            });
        this.store
            .select(dashboardContextSelector)
            .pipe(
                filter(data => data !== null),
                first()
            )
            .subscribe(() => {
                void this.initServices();
            });
        this.hotkeysService
            .addShortcut({
                keys: 'control.shift.s',
                description: 'Toggle menu visualization'
            })
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.expandedToggle();
            });
    }

    async initServices() {
        this.dataSource = await this.store
            .select(sideBarSelector)
            .pipe(first())
            .toPromise();
        this.store
            .select(starredSideBarSelector)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(res => {
                this.tc2.dataNodes = <any[]>res.services;
                this.tc2.expandAll();
                this.cdr.detectChanges();
            });
        this.originalDataSource = JSON.parse(JSON.stringify(this.dataSource));
        this.tc.dataNodes = this.dataSource.services;
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    inputChangeHandler(e) {
        this.dataSource.services.find(
            service => service.name === e.name
        ).children = this.originalDataSource.services
            .find(service => service.name === e.name)
            .children.filter(child => {
                return child.name
                    .toLowerCase()
                    .includes(e.filter.toLowerCase());
            });
        this.dataSource = JSON.parse(JSON.stringify(this.dataSource));
        this.tc = new NestedTreeControl(GetChildren);
        this.tc.dataNodes = this.dataSource.services;
        this.tc.expandAll();
    }

    originalHasChildren(node) {
        const originalNode = this.originalDataSource.services.find(
            service => service.name === node.name
        );
        return originalNode && originalNode.children.length > 10;
    }

    handleClick(node) {
        this.expanded$.pipe(first()).subscribe(expanded => {
            if (!expanded) {
                this.expandedToggle();
            }
            switch (node.type) {
                case 'instance':
                    void this.instanceRedirection(node);
                    break;
                case 'all-clouds':
                    this.allCloudsRedirection(node);
                    break;
                case 'link':
                    window.open(node.path);
                    break;
                case 'help':
                    this.showHelp();
                    break;
            }
        });
    }

    async allCloudsRedirection(node) {
        this.store
            .select(allCloudsDashboardSelector)
            .pipe(first())
            .subscribe(dashboards => {
                const availableDashboards = dashboards.filter(
                    d => d['is-available-for-user']
                );
                void this.router.navigate([
                    '/' + node.path,
                    availableDashboards[0].id
                ]);
            });
    }

    async instanceRedirection(node) {
        const settings = await this.store
            .select(settingsSelector)
            .pipe(first())
            .toPromise();
        const dashboards = await this.dashboardService
            .getDashboards(node.instanceId)
            .toPromise();
        let dashboard = dashboards.find(
            item =>
                item['is-available-for-user'] &&
                settings &&
                settings.dashboardId === item.id
        );
        if (!dashboard) {
            dashboard = dashboards.find(item => item['is-available-for-user']);
        }
        this.store.dispatch(
            GeneralActions.instanceLoad({
                serviceId: node.serviceId,
                instanceId: node.instanceId,
                dashboardId: dashboard !== undefined ? dashboard.id : null
            })
        );
        if (dashboard)
            this.router.navigate([
                '/' + node.path,
                node.instanceId,
                dashboard.url
            ]);
    }

    showHelp() {
        this.dialog.open(HelpDialogComponent, {
            width: '800px'
        });
    }

    expandedToggle() {
        this.store.dispatch(GeneralActions.toggleSideMenu());
    }
}
